<template>
  <div class="ml-home">
    <div class="modal-register">
      <div class="modal-body">
        <div class="d-flex justify-content-center align-items-center">
          <img alt="logo" src="~/mlearn/icons/menu/mobiedu.png" class="pointer logo" @click="redirectHome">
        </div>
        <!--    <img alt="logo" src="~/mlearn/icons/modal/close-modal.svg" class="close pointer position-absolute" @click="showModal = false">-->
        <div class="content">
          <div class="title text-center">Đăng ký tài khoản HOÀN TOÀN miễn phí để nhận</div>
          <div class="title p-0 text-center">nhiều ưu đãi hấp dẫn</div>
        </div>
        <div class="title-mobile text-center">Đăng ký tài khoản HOÀN TOÀN miễn phí</div>
        <div class="title-mobile p-0 text-center">để nhận nhiều ưu đãi hấp dẫn</div>
        <!--    <div class="description">Học tập và giao lưu với hàng triệu học viên trên mọi miền đất nước</div>-->
        <div class="group-item account">
          <div class="label">Họ tên <span>*</span></div>
          <div>
            <input type="text" v-model="form.fullName" placeholder="Nhập họ tên"/>
          </div>
        </div>
        <div class="group-item email">
          <div class="label">Email <span>*</span></div>
          <div>
            <input type="text" v-model="form.email" placeholder="Email"/>
          </div>
        </div>
        <div class="group-item mobile">
          <div class="label">Số điện thoại <span>*</span></div>
          <div>
            <input type="text" v-model="form.mobile" placeholder="Nhập số điện thoại để nhận hỗ trợ" autocomplete="mobile number"/>
          </div>
        </div>
        <div class="group-item password">
          <div class="label">Mật khẩu <span>*</span></div>
          <div class="position-relative">
            <input :type="showpassword ? 'text' : 'password'" v-model="form.password" placeholder="Nhập mật khẩu" autocomplete="new password"/>
            <img :src="showpassword ? IconHide : IconShow" alt="View" class="action pointer position-center" @click="showpassword = !showpassword">
          </div>
        </div>
        <div class="group-item password-confirm">
          <div class="label">Xác nhận mật khẩu <span>*</span></div>
          <div class="position-relative">
            <input :type="showpassword ? 'text' : 'password'" v-model="form.repeat_password" placeholder="Xác nhận mật khẩu" autocomplete="confirm password"/>
            <img :src="showpassword ? IconHide : IconShow" alt="View" class="action pointer position-center" @click="showpassword = !showpassword">
          </div>
        </div>
        <!--    <div class="group-item">-->
        <!--      <b-form-checkbox v-model="term" :unchecked-value="0">-->
        <!--        Tôi đồng ý với chính sách và điều khoản sử dụng của Mlearn-->
        <!--      </b-form-checkbox>-->
        <!--    </div>-->
        <!-- <div class="group-item">
          <recaptcha />
        </div> -->
        <div class="group-item d-flex justify-content-center align-items-center">
          <button @click="register()" class="register" :class="loading ? 'disabled' : ''" :disabled="loading">Đăng ký</button>
        </div>
        <div class="or text-center">
          <div class="line mr-2"></div>
          Hoặc
          <div class="line ml-2"></div>
        </div>
        <div class="d-flex justify-content-center align-items-center login-other">
          <div class="group-item group-item-footer fb">
            <button type="button" class="facebook" @click="fbAuthUser()">
              <img src="~/mlearn/icons/modal/facebook.svg" alt="" class="mr-1 fb-log"/> <span>Facebook</span>
            </button>
          </div>
          <div class="group-item group-item-footer">
            <button type="button" class="google" @click="loginGoogle()">
              <img src="~/mlearn/icons/modal/mail.svg" width="30" height="20" alt="" class="gg-log"/> <span>Google</span>
            </button>
          </div>
        </div>
        <div class="group-item-footer no-account text-center no-account-login">
          Bạn có tài khoản? <span class="register text-primary pointer" @click="login()">Đăng nhập</span> ngay.
        </div>
        <div class="group-item-footer no-account text-center">
          Bằng việc đăng ký, bạn đã đồng ý với các <span class="register text-primary pointer" @click="security()">điều khoản và chính sách</span> chúng tôi.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import {register, loginGoogle, loginFacebook} from '~/common/api/auth'
import IconShow from '~/assets/mlearn/icons/change-pass/show.svg'
import IconHide from '~/assets/mlearn/icons/change-pass/hide.svg'

export default {
  layout: 'detailLogin',
  data () {
    return {
      IconShow,
      IconHide,
      loading: false,
      showModal: true,
      form: {
        email: '',
        password: '',
        repeat_password: '',
        fullName: '',
        mobile: ''
      },
      term: 1,
      showpassword: false
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },
  // created () {
  //   this.showModal = true
  // },
  // beforeDestroy () {
  //   this.$recaptcha.destroy()
  // },
  methods: {
    redirectHome () {
      this.$router.push('/')
    },
    async loginGoogle () {
      if (this.isMobile) {
        const path = this.$route.fullPath
        Cookies.set('redirect', path)
        const url = 'https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20profile%20email&response_type=token%20id_token&redirect_uri=' + process.env.NUXT_ENV_GOOGLE_REDIECT + '&client_id=' + process.env.NUXT_ENV_GOOGLE_CLIENT_ID + '&nonce=thtfmJzzrwhiG-LYFecUT&&flowName=GeneralOAuthFlow'
        window.location.href = url
      } else {
        if ('storage' in navigator && 'estimate' in navigator.storage) {
          const jsHeapSizeLimit = performance.memory.jsHeapSizeLimit
          const {quota} = await navigator.storage.estimate()

          if (quota < jsHeapSizeLimit) {
            const path = this.$route.fullPath
            Cookies.set('redirect', path)
            const url = 'https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20profile%20email&response_type=token%20id_token&redirect_uri=' + process.env.NUXT_ENV_GOOGLE_REDIECT + '&client_id=' + process.env.NUXT_ENV_GOOGLE_CLIENT_ID + '&nonce=thtfmJzzrwhiG-LYFecUT&&flowName=GeneralOAuthFlow'
            window.location.href = url
          }
        }
        this.loading = true
        this.$gAuth.signIn()
          .then((googleUser) => {
            const accessToken = googleUser.getAuthResponse().id_token
            this.$emit('Login')
            const params = {
              accessToken
            }
            loginGoogle(params)
              .then((response) => {
                this.$emit('Login')
                this.$store.commit('SET_AUTHENTICATED', true)
                this.$store.commit('SET_TOKEN', response.data.accessToken)
                this.$store.commit('SET_USER', {
                  email: response.data.email,
                  fullName: response.data.fullName,
                  userName: response.data.userName,
                  avatar: response.data.avatar,
                  uuid: response.data.uuid
                })
                this.connectSocket()
                this.$router.go(this.$router.currentRoute)
              })
              .catch(() => {
              })
              .finally(() => {
                this.loading = false
              })
          })
      }
    },
    fbAuthUser () {
      window.FB.getLoginStatus((response) => {
        if (response.status !== 'connected') {
          return window.FB.login((response) => {
            this.handleFbResponse(response)
          }, {
            scope: 'public_profile,email',
            enable_profile_selector: true,
            auth_type: 'rerequest',
            return_scopes: true
          })
        } else {
          this.handleFbResponse(response)
        }
      })
    },
    handleFbResponse (response) {
      if (response.status === 'connected') {
        const accessToken = response.authResponse.accessToken
        // const facebook_id = response.authResponse.userID
        const params = {
          accessToken
        }
        this.loading = true
        loginFacebook(params)
          .then((response) => {
            this.$emit('Login')
            this.$store.commit('SET_AUTHENTICATED', true)
            this.$store.commit('SET_TOKEN', response.data.accessToken)
            this.$store.commit('SET_USER', {
              email: response.data.email,
              fullName: response.data.fullName,
              userName: response.data.userName,
              avatar: response.data.avatar,
              uuid: response.data.uuid
            })
            this.connectSocket()
            this.$router.go(this.$router.currentRoute)
          })
          .catch(() => {
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        return false
      }
    },
    register () {
      if (this.loading) {
        return
      }
      let checkError = false
      let message = ''
      this.form.fullName = this.form.fullName.trim()
      this.form.mobile = this.form.mobile.trim()
      this.form.email = this.form.email.trim()
      this.form.password = this.form.password.trim()
      this.form.repeat_password = this.form.repeat_password.trim()
      if (this.form.mobile && this.form.mobile.startsWith('84')) {
        this.mobile = '0' + this.form.mobile.substring(2)
      }
      if (!this.form.fullName) {
        message = 'Vui lòng nhập họ tên'
        checkError = true
      }
      if (checkError) {
        this.$notify({
          message,
          type: 'warning'
        })
        return
      }
      if (!this.form.email) {
        message = 'Vui lòng nhập email'
        checkError = true
      }
      if (checkError) {
        this.$notify({
          message,
          type: 'warning'
        })
        return
      }
      if (!this.form.mobile) {
        message = 'Vui lòng nhập số điện thoại'
        checkError = true
      }
      if (checkError) {
        this.$notify({
          message,
          type: 'warning'
        })
        return
      }
      if (!this.form.password) {
        message = 'Vui lòng nhập mật khẩu'
        checkError = true
      }
      if (checkError) {
        this.$notify({
          message,
          type: 'warning'
        })
        return
      }
      if (!this.form.repeat_password) {
        message = 'Vui lòng nhập mật khẩu'
        checkError = true
      }
      if (checkError) {
        this.$notify({
          message,
          type: 'warning'
        })
        return
      }
      if (this.form.fullName.length > 50) {
        message = 'Họ tên không được dài quá 50 ký tự'
        checkError = true
      }
      if (checkError) {
        this.$notify({
          message,
          type: 'warning'
        })
        return
      }
      if (!this.$helper.validEmail(this.form.email)) {
        message = 'Vui lòng kiểm tra lại email'
        checkError = true
      }
      if (checkError) {
        this.$notify({
          message,
          type: 'warning'
        })
        return
      }
      if (!this.$helper.validPhone(this.form.mobile)) {
        message = 'Vui lòng kiểm tra lại số điện thoại'
        checkError = true
      }
      if (checkError) {
        this.$notify({
          message,
          type: 'warning'
        })
        return
      }
      if (this.form.password.length < 8) {
        message = 'Mật khẩu cần có chữ số, chữ hoa, chữ thường, ký tự đặc biệt và tối thiểu từ 8 ký tự trở lên'
        checkError = true
      }
      if (checkError) {
        this.$notify({
          message,
          type: 'warning'
        })
        return
      }
      if (this.form.password !== this.form.repeat_password) {
        message = 'Mật khẩu không trùng khớp'
        checkError = true
      }
      if (checkError) {
        this.$notify({
          message,
          type: 'warning'
        })
        return
      }
      if (this.form.password !== '' && this.$helper.emoji(this.form.password) !== '') {
        console.log('check: ', this.$helper.emoji(this.form.password))
        message = 'Mật khẩu cần có chữ số, chữ hoa, chữ thường, ký tự đặc biệt và tối thiểu từ 8 ký tự trở lên'
        checkError = true
      }
      if (checkError) {
        this.$notify({
          message,
          type: 'warning'
        })
        return
      }
      if (this.term === 0) {
        message = 'Bạn cần đồng ý với chính sách điều khoản của chúng tôi'
        checkError = true
      }
      this.loading = true
      // add google recaptcha token
      // let recaptchaToken = ''
      // try {
      //   recaptchaToken = await this.$recaptcha.getResponse()
      // } catch (error) {
      //   console.log('Get captcha error:', error)
      // }
      // if (!recaptchaToken) {
      //   this.$notify({
      //     message: 'Chưa xác thực captcha',
      //     type: 'warning'
      //   })
      //   return
      // }
      this.form.recaptcha_token = ''
      this.loading = true
      register(this.form)
        .then(() => {
          // this.$notify({
          //   message: 'Đăng ký tài khoản thành công, vui lòng kiểm tra email và kích hoạt tài khoản.',
          //   type: 'success'
          // })
          this.reset()
          this.$router.push('/user/login')
        }).catch((err) => {
          this.$notify({
            message: err.response.data.message,
            type: 'error'
          })
        })
        .finally(() => {
          this.loading = false
          // this.$recaptcha.reset()
        })
    },
    login () {
      this.showModal = false
      this.$router.push('/user/login')
    },
    security () {
      this.$router.push('/thong-tin-khac?tab=chinh-sach-bao-mat')
    },
    reset () {
      this.form = {
        email: '',
        password: '',
        repeat_password: '',
        fullName: '',
        mobile: ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ml-home {
  background-color: #004390;
  padding: 24px 40px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  @media (max-width: 576px) {
    padding: 38px 40px;
  }

  .modal-register {
    border-radius: 12px;
    margin: 0;
    background-color: var(--white);
    max-width: 650px;
    width: 100%;
    @media (max-width: 576px) {
      padding: 15px;
      margin: 0;
    }
    .modal-body {
      padding: 24px 39px;
      @media (max-width: 576px) {
        padding: 14px 8px;
      }

      .content {
        padding-top: 30px;
        padding-bottom: 16px;
        @media (max-width: 576px) {
          padding-top: 10px;
          padding-bottom: 6px;
        }
      }

      .close {
        top: 24px;
        right: 24px;
        @media (max-width: 576px) {
          top: 16px;
          right: 24px;
        }
      }

      .logo {
        width: 240px;
        height: 40px;
        @media (max-width: 576px) {
          width: 180px;
          height: 28px;
        }
      }

      .title {
        line-height: 32px;
        font-weight: 700;
        font-size: 24px;
        color: #005396;
        @media (max-width: 576px) {
          display: none;
          font-size: 16px;
        }
      }

      .title-mobile {
        display: none;
        @media (max-width: 576px) {
          font-weight: 700;
          font-size: 16px;
          color: #004390;
          display: block;
        }
      }

      .description {
        font-weight: 500;
        font-size: 11px;
        color: #65676B;
      }

      .email {
        margin-bottom: 12px;
        @media (max-width: 576px) {
          margin-bottom: 0px;
        }
      }

      .account {
        margin-bottom: 12px;
        @media (max-width: 576px) {
          margin-bottom: 0px;
        }
      }

      .mobile {
        margin-bottom: 12px;
        @media (max-width: 576px) {
          margin-bottom: 0px;
        }
      }

      .password {
        margin-bottom: 12px;
        @media (max-width: 576px) {
          margin-bottom: 0px;
        }
      }

      .password-confirm {
        margin-bottom: 29px;
        @media (max-width: 576px) {
          margin-bottom: 8px;
        }
      }

      .login-other {
        margin-bottom: 24px;
        @media (max-width: 576px) {
          margin-bottom: 12px;
        }

        .fb {
          margin-right: 39px;
          @media (max-width: 576px) {
            margin-right: 6px;
          }
        }
      }

      .group-item {
        //padding-top: 20px;
        @media (max-width: 576px) {
          padding-top: 12px;
        }

        .position-relative {
          margin-bottom: 15px;
          @media (max-width: 576px) {
            margin-bottom: 0px;
          }
        }

        .label {
          font-size: 16px;
          //padding-left: 16px;
          margin-bottom: 4px;
          line-height: 22px;
          color: #1d1d1b;
          @media (max-width: 576px) {
            font-size: 14px;
          }
        }

        input {
          background: #ffffff;
          border: thin solid #37A8FF !important;
          border-radius: 5px;
          width: 100%;
          height: 50px;
          padding: 5px 20px;
          font-size: 14px;
          color: #333333;
          @media (max-width: 576px) {
            padding: 10px 16px;
            font-size: 12px;
          }

          &::placeholder {
            color: #808080;
          }
        }

        .custom-control-label {
          font-size: 12px;
          cursor: pointer;
        }

        button {
          border-radius: 20px;
          max-width: 232px;
          font-weight: 700;
          padding: 10px 30px;

          &.register {
            font-weight: 700;
            text-transform: uppercase;
            font-size: 14px;
            height: 40px;
            background: #004390;
            color: var(--white);
            @media (max-width: 576px) {
              padding: 10px 24px;
            }
          }
          &.disabled {
            background: #5f6a77 !important;
            color: #e7e0e0 !important;
          }

          &.facebook {
            background: #004390;
            color: #ffffff;
            padding: 10px 30px;
            font-size: 14px;
            height: 40px;
            font-weight: 700;
            text-align: center;
            @media (max-width: 576px) {
              padding: 10px 20px;
            }
            @media (max-width: 385px) {
              span {
                display: none;
              }
            }

            .fb-log {
              @media (max-width: 576px) {
                padding-bottom: 3px;
              }
            }
          }

          &.google {
            color: #ffffff;
            background: #D14D41;
            padding: 10px 30px;
            font-size: 14px;
            height: 40px;
            font-weight: 700;
            text-align: center;
            @media (max-width: 576px) {
              padding: 8px 18px;
            }
            @media (max-width: 385px) {
              span {
                display: none;
              }
            }

            .gg-log {
              @media (max-width: 576px) {
                font-size: 12px;
              }
            }
          }
        }

        &.password {
          .action {
            right: 16px;
          }
        }
      }

      .no-account-login {
        margin-bottom: 12px;
        font-size: 19px;
        font-weight: 400;
        color: #6B6D70;

        span {
          font-weight: 700;
          text-decoration: #004390 underline;
        }

        a {
          font-weight: 500;
        }
      }

      .group-item-footer {
        //padding-top: 10px;

        &.no-account {
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
          color: #333333;

          span {
            text-decoration: #004390 underline;
          }

          a {
            font-weight: 500;
          }
        }
      }

      .position-center {
        left: 95%;
      }

      .line {
        height: 1px;
        width: 350px;
        background: #004390;
        //margin: 20px 0;
      }

      .or {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        @media (max-width: 576px) {
          padding-top: 10px;
          padding-bottom: 0px;
        }
      }
    }
  }
}
</style>
