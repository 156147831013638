<template>
  <div class="ml-home">
    <div class="modal-forget">
      <div class="modal-body">
        <div class="password-wap mx-auto">
          <div class="w-100 d-flex justify-content-center">
            <img alt="logo" src="~/mlearn/icons/menu/mobiedu.png" class="logo pointer" @click="redirectHome">
          </div>
          <div class="content">
            <div class="title text-center">Điền lại số điện thoại bạn đã đăng kí</div>
          </div>
          <template v-if="!status">
            <div class="group-item">
              <input type="text" class="input-phone" v-model="email" placeholder="Nhập số điện thoại"/>
            </div>
            <div class="group-item-forget d-flex justify-content-center">
              <button @click="forgetPassword()" class="forget" :class="{disable:loading}">Gửi yêu cầu</button>
            </div>
            <div class="no-account text-center">Bạn có tài khoản? <span class="pointer" @click="login">Đăng nhập</span> ngay.</div>
          </template>
          <template v-else>
            <div class="description">Mã OTP đã được gửi về số điện thoại của bạn!</div>
            <div class="group-item">
              <div class="label">Nhập mật khẩu mới</div>
              <div class="position-relative">
                <input :type="showpassword ? 'text' : 'password'" v-model="newPassword" placeholder="Nhập mật khẩu mới"/>
                <img :src="showpassword ? IconHide : IconShow" alt="View" class="action pointer position-center" @click="showpassword = !showpassword">
              </div>
            </div>
            <div class="group-item">
              <div class="label">Nhập lại mật khẩu mới</div>
              <div class="position-relative">
                <input :type="shownewpassword ? 'text' : 'password'" v-model="rePassword" placeholder="Nhập lại mật khẩu mới"/>
                <img :src="shownewpassword ? IconHide : IconShow" alt="View" class="action pointer position-center" @click="shownewpassword = !shownewpassword">
              </div>
            </div>
            <div class="group-item">
              <div class="label">Nhập mã OTP</div>
              <div class="position-relative">
                <input v-model="otp" placeholder="Nhập mã OTP"/>
              </div>
            </div>
            <div class="group-item float-right">
              <button @click="resetPassword()" class="forget" :class="{disable:loading}">Đặt lại mật khẩu</button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import {forgetPassword} from '~/common/api/auth'
import {changePassApp} from '~/common/api/forgot'
import IconShow from '~/assets/mlearn/icons/change-pass/show.svg'
import IconHide from '~/assets/mlearn/icons/change-pass/hide.svg'

export default {
  layout: 'detailLogin',
  data () {
    return {
      IconShow,
      IconHide,
      showModal: true,
      email: null,
      loading: false,
      newPassword: '',
      rePassword: '',
      otp: '',
      status: '',
      showpassword: false,
      shownewpassword: false
    }
  },
  // created () {
  //   this.showModal = true
  // },
  methods: {
    redirectHome () {
      this.$router.push('/')
    },
    forgetPassword () {
      if (this.loading) {
        return
      }
      if (!this.email) {
        this.$notify({
          message: 'Vui lòng điền số điện thoại',
          type: 'error'
        })
        return
      }
      if (!this.$helper.validPhone(this.email)) {
        this.$notify({
          message: 'Số điện thoại không đúng định dạng',
          type: 'error'
        })
        return
      }
      this.loading = true
      forgetPassword(this.email)
        .then(() => {
          this.status = 'success'
        })
        .catch((err) => {
          this.$notify({
            message: err.response.data.message,
            type: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    hide () {
      this.status = ''
      this.email = null
      this.newPassword = ''
      this.rePassword = ''
      this.otp = ''
      this.showpassword = false
      this.shownewpassword = false
    },
    resetPassword () {
      this.loading = true
      const data = {
        mobile: this.email,
        newPassword: this.newPassword,
        rePassword: this.rePassword,
        otp: this.otp
      }
      changePassApp(data)
        .then(() => {
          this.$router.push('/user/login')
          this.$notify({
            message: 'Đặt lại mật khẩu thành công.',
            type: 'sucess'
          })
        })
        .catch((err) => {
          this.$notify({
            message: err.response.data.message,
            type: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    login () {
      this.$router.push('/user/login')
      this.$router.push('/user/login')
    }
  }
}
</script>
<style lang="scss" scoped>
.ml-home {
  background-color: #004390;
  padding: 265px 40px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  @media (max-width: 576px) {
    padding: 300px 40px;
  }

  .modal-forget {
    border-radius: 12px;
    margin: 0;
    background-color: var(--white);
    max-width: 920px;
    @media (max-width: 576px) {
      padding: 20px;
    }

    .logo {
      width: 240px;
      height: 40px;
      @media (max-width: 576px) {
        width: 180px;
        height: 28px;
      }
    }

    .title {
      width: 100%;
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      color: #005396;
      padding-top: 30px;
      padding-bottom: 20px;
      @media (max-width: 576px) {
        font-size: 18px;
        padding-top: 18px;
        padding-bottom: 10px;
      }
    }

    padding: 38px;

    .close {
      top: 24px;
      right: 24px;
    }

    .description {
      font-weight: 500;
      font-size: 14px;
      color: #65676B;
      margin-bottom: 12px;
    }

    .group-item {
      .input-phone {
        border: thin solid #004390 !important;
        border-radius: 6px;
        width: 100%;
        padding: 5px 20px;
        font-size: 14px;
        height: 50px;
        color: #333333;
        background: #ffffff;

        &::placeholder {
          color: #808080;
        }
      }

      input {
        border: thin solid #004390 !important;
        border-radius: 6px;
        width: 100%;
        padding: 5px 20px;
        font-size: 14px;
        height: 50px;
        color: #333333;
        background: #ffffff;

        &::placeholder {
          color: #808080;
        }
      }

      button {
        transition: 0.2s ease-in-out all;
        cursor: pointer;
        border: thin solid #004390;
        border-radius: 20px;
        background-color: #004390;
        padding: 10px 30px;
        width: -moz-fit-content;
        width: fit-content;
        height: 40px;
        color: white;
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;

        &.forget {
          font-weight: bold;
          font-size: 15px;
          background: #004390;
          color: var(--white);
        }
      }

      .position-relative {
        margin-bottom: 16px;
      }

      .action {
        right: 16px;
      }

      .position-center {
        left: 95%;
      }
    }

    .group-item-forget {
      margin-top: 30px;

      button {
        transition: 0.2s ease-in-out all;
        cursor: pointer;
        border: thin solid #004390;
        border-radius: 20px;
        background-color: #004390;
        padding: 10px 30px;
        width: -moz-fit-content;
        width: fit-content;
        height: 40px;
        color: white;
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;

        &.forget {
          font-weight: bold;
          font-size: 15px;
          background: #004390;
          color: var(--white);
        }
      }
    }

    .no-account {
      margin-top: 23px;
      font-size: 14px;
      line-height: 24px;

      span {
        -webkit-text-decoration-line: underline;
        color: #21a6f1;
        text-decoration-line: underline;
        font-weight: 700;
      }
    }
  }
}
</style>
