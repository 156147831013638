<template>
  <div class="ml-home">
    <div class="modal-login">
      <div class="modal-body">
        <div class="d-flex justify-content-center align-items-center">
          <img alt="logo" src="~/mlearn/icons/menu/mobiedu.png" class="pointer logo" @click="redirectHome">
        </div>
        <!--    <img alt="close" src="~/mlearn/icons/modal/close-modal.svg" class="close pointer position-absolute" @click="showModal = false">-->
        <div class="content">
          <div class="title text-center">Đăng nhập ngay để trải nghiệm</div>
          <div class="title text-center p-0">những tính năng hấp dẫn</div>
        </div>
        <!--    <div class="description">Học tập và giao lưu với hàng triệu học viên trên mọi miền đất nước</div>-->
        <div class="group-item account">
          <div class="label">Số điện thoại *</div>
          <div>
            <input type="text" @keyup.enter="login" v-model="email" placeholder="Email/Số điện thoại"/>
          </div>
        </div>
        <div class="group-item password">
          <div class="label">Mật khẩu *</div>
          <div class="position-relative">
            <input @keyup.enter="login" :type="showpassword ? 'text' : 'password'" v-model="password" placeholder="Mật khẩu"/>
            <img :src="showpassword ? IconHide : IconShow" alt="View" class="action pointer position-center" @click="showpassword = !showpassword">
          </div>
        </div>
        <div class="group-item forget-password text-left">
          <span class="forget text-primary" @click="forgetPassword()">
            Quên mật khẩu
          </span>
        </div>
        <div class="group-item d-flex justify-content-center align-items-center">
          <button type="button" @click="login()" class="login" :class="loading ? 'disabled' : ''" :disabled="loading">Đăng nhập</button>
        </div>
        <div class="or text-center">
          <div class="line mr-2"></div>
          Hoặc
          <div class="line ml-2"></div>
        </div>
        <div class="d-flex justify-content-center align-items-center login-other">
          <div class="group-item fb">
            <button type="button" class="facebook" @click="fbAuthUser()">
              <img src="~/mlearn/icons/modal/facebook.svg" alt="" class="mr-1 fb-log"/> <span>Facebook</span>
            </button>
          </div>
          <div class="group-item">
            <button type="button" class="google" @click="loginGoogle()">
              <img src="~/mlearn/icons/modal/mail.svg" width="30" height="20" alt="" class="gg-login"/> <span>Google</span>
            </button>
          </div>
        </div>
        <div class="group-item no-account text-center">
          Bạn chưa có tài khoản? <span class="register text-primary pointer" @click="register()">Đăng ký</span> ngay.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Cookies from 'js-cookie'
import Cookies from 'js-cookie'
import IconShow from '~/assets/mlearn/icons/change-pass/show.svg'
import IconHide from '~/assets/mlearn/icons/change-pass/hide.svg'
import {login, loginGoogle, loginFacebook} from '~/common/api/auth'
import {getToken} from '~/common/utils/auth'

export default {
  layout: 'detailLogin',
  data () {
    return {
      IconShow,
      IconHide,
      showModal: true,
      loading: false,
      email: null,
      password: null,
      showpassword: false,
      countTimeLogin: 0,
      lockLoggin: false
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },
  // created () {
  //   this.showModal = true
  // },
  methods: {
    redirectHome () {
      this.$router.push('/')
    },
    async loginGoogle () {
      if (this.isMobile) {
        // const path = Cookies.get('redirect')
        // Cookies.set('redirect', path)
        const url = 'https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20profile%20email&response_type=token%20id_token&redirect_uri=' + process.env.NUXT_ENV_GOOGLE_REDIECT + '&client_id=' + process.env.NUXT_ENV_GOOGLE_CLIENT_ID + '&nonce=thtfmJzzrwhiG-LYFecUT&&flowName=GeneralOAuthFlow'
        window.location.href = url
      } else {
        if ('storage' in navigator && 'estimate' in navigator.storage) {
          const jsHeapSizeLimit = performance.memory.jsHeapSizeLimit
          const {quota} = await navigator.storage.estimate()

          if (quota < jsHeapSizeLimit) {
            // const path = this.$route.fullPath
            // Cookies.set('redirect', path)
            console.log('redirect')
            const url = 'https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20profile%20email&response_type=token%20id_token&redirect_uri=' + process.env.NUXT_ENV_GOOGLE_REDIECT + '&client_id=' + process.env.NUXT_ENV_GOOGLE_CLIENT_ID + '&nonce=thtfmJzzrwhiG-LYFecUT&&flowName=GeneralOAuthFlow'
            window.location.href = url
          }
        }
        this.loading = true
        this.$gAuth.signIn()
          .then((googleUser) => {
            const accessToken = googleUser.getAuthResponse().id_token
            const params = {
              accessToken
            }
            loginGoogle(params)
              .then((response) => {
                this.$emit('Login')
                this.$store.commit('SET_AUTHENTICATED', true)
                this.$store.commit('SET_TOKEN', response.data.accessToken)
                this.$store.commit('SET_USER', {
                  email: response.data.email,
                  fullName: response.data.fullName,
                  userName: response.data.userName,
                  avatar: response.data.avatar,
                  uuid: response.data.uuid
                })
                this.connectSocket()
                this.$router.back()
              })
              .catch(() => {
              })
              .finally(() => {
                this.loading = false
              })
          })
          .catch(() => {
          })
      }
    },
    fbAuthUser () {
      window.FB.getLoginStatus((response) => {
        if (response.status !== 'connected') {
          return window.FB.login((response) => {
            this.handleFbResponse(response)
          }, {
            scope: 'public_profile,email',
            enable_profile_selector: true,
            auth_type: 'rerequest',
            return_scopes: true
          })
        } else {
          this.handleFbResponse(response)
        }
      })
    },
    handleFbResponse (response) {
      if (response.status === 'connected') {
        const accessToken = response.authResponse.accessToken
        const params = {
          accessToken
        }
        this.loading = true
        loginFacebook(params)
          .then((response) => {
            this.$emit('Login')
            this.$store.commit('SET_AUTHENTICATED', true)
            this.$store.commit('SET_TOKEN', response.data.accessToken)
            this.$store.commit('SET_USER', {
              email: response.data.email,
              fullName: response.data.fullName,
              userName: response.data.userName,
              avatar: response.data.avatar,
              uuid: response.data.uuid
            })
            this.connectSocket()
            this.$router.go(this.$router.currentRoute)
          })
          .catch(() => {
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        return false
      }
    },
    login () {
      if (this.loading) {
        return
      }
      if (!this.email) {
        this.$notify({
          type: 'error',
          message: 'Vui lòng nhập số diện thoại hoặc Email'
        })
        return
      }
      if (!this.password) {
        this.$notify({
          type: 'error',
          message: 'Vui lòng nhập mật khẩu'
        })
        return
      }
      if (!this.$helper.validEmail(this.email) && !this.$helper.validPhone(this.email)) {
        this.$notify({
          type: 'error',
          message: 'Email/Số điện thoại không đúng định dạng'
        })
        return
      }

      if (this.password.length < 6 || this.password.length > 20) {
        this.$notify({
          type: 'error',
          message: 'Mật khẩu dài từ 6 - 20 ký tự'
        })
        return
      }
      if (this.$helper.emoji(this.password)) {
        this.$notify({
          type: 'error',
          message: 'Mật khẩu gồm chữ cái, số hoặc các kí tự đặc biệt !@#$%^&*()+-'
        })
        return
      }
      if (this.countTimeLogin < 5) {
        this.countTimeLogin++
      } else {
        if (!this.lockLoggin) {
          this.$notify({
            type: 'error',
            message: 'Bạn đã nhập quá số lần cho phép, vui lòng thử lại sau 10 phút hoặc chọn Quên mật khẩu để lấy lại mật khẩu'
          })
          this.lockLoggin = true
          setTimeout(() => {
            this.countTimeLogin = 0
            this.lockLoggin = false
          }, 600000)
        }
        return
      }
      const params = {
        email: this.email,
        password: this.password
      }
      this.loading = true
      login(params)
        .then((response) => {
          console.log('response: ', response)
          this.$emit('Login')
          this.$store.commit('SET_AUTHENTICATED', true)
          this.$store.commit('SET_TOKEN', response.data.accessToken)
          this.$store.commit('SET_USER', {
            email: response.data.email,
            fullName: response.data.fullName,
            userName: response.data.userName,
            avatar: response.data.avatar,
            uuid: response.data.uuid
          })
          this.connectSocket()
          const path = Cookies.get('redirect')
          this.$router.push(path)
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    connectSocket () {
      this.$socket.io.opts.query = {
        token: getToken()
      }
      this.$socket.connect()
    },
    register () {
      this.showModal = false
      this.$router.push('/user/register')
    },
    forgetPassword () {
      this.$router.push('/user/forget')
    }
  }
}
</script>

<style lang="scss" scoped>
.ml-home {
  background-color: #004390;
  padding: 135px 40px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  @media (max-width: 576px) {
    padding: 70px 40px 200px;
  }

  .modal-login {
    border-radius: 12px;
    margin: 0;
    background-color: var(--white);
    max-width: 650px;
    width: 100%;
    @media (max-width: 576px) {
      padding: 15px;
      margin: 0;
    }

    .modal-body {
      padding: 38px;
      @media (max-width: 576px) {
        padding: 14px 8px;
      }

      .content {
        padding-top: 30px;
        padding-bottom: 20px;
        @media (max-width: 576px) {
          padding-top: 10px;
          padding-bottom: 6px;
        }
      }

      .close {
        top: 24px;
        right: 24px;
        @media (max-width: 576px) {
          top: 16px;
          right: 24px;
        }
      }

      .logo {
        width: 240px;
        height: 40px;
        @media (max-width: 576px) {
          width: 180px;
          height: 28px;
        }
      }

      .title {
        line-height: 32px;
        font-weight: 700;
        font-size: 24px;
        color: #005396;
        @media (max-width: 576px) {
          font-size: 18px;
        }
      }

      .position-center {
        left: 94%;
      }

      .description {
        font-weight: 500;
        font-size: 11px;
        color: #65676B;
      }

      .account {
        margin-bottom: 15px;
        @media (max-width: 576px) {
          margin-bottom: 8px;
        }
      }

      .password {
      }

      .forget-password {
        margin-top: 12px;
        margin-bottom: 29px;
        @media (max-width: 576px) {
          margin-top: 2px;
          margin-bottom: 12px;
        }
      }

      .login-other {
        margin-bottom: 37px;
        @media (max-width: 576px) {
          margin-bottom: 12px;
        }

        .fb {
          margin-right: 12px;
        }
      }

      .group-item {
        @media (max-width: 576px) {
          padding-top: 10px;
        }

        .label {
          font-size: 16px;
          //padding-left: 16px;
          margin-bottom: 4px;
          line-height: 22px;
          color: #1d1d1b;
          @media (max-width: 576px) {
            font-size: 14px;
          }
        }

        input {
          background: #ffffff;
          border: thin solid #37A8FF !important;
          border-radius: 5px;
          width: 100%;
          height: 50px;
          padding: 5px 20px;
          font-size: 14px;
          color: #333333;
          @media (max-width: 576px) {
            padding: 10px 16px;
            font-size: 12px;
          }

          &::placeholder {
            color: #808080;
          }
        }

        .forget {
          font-weight: 400;
          font-size: 14px;
          text-decoration: #004390 underline;
        }

        .forget:hover {
          cursor: pointer;
        }

        button {
          border-radius: 20px;
          max-width: 232px;
          font-weight: 700;
          padding: 10px 30px;

          &.login {
            font-weight: 700;
            text-transform: uppercase;
            font-size: 14px;
            height: 40px;
            background: #004390;
            color: var(--white);
            @media (max-width: 576px) {
              padding: 10px 24px;
            }
          }
          &.disabled {
            background: #5f6a77 !important;
            color: #e7e0e0 !important;
          }

          &.facebook {
            background: #004390;
            color: #ffffff;
            padding: 10px 30px;
            font-size: 14px;
            height: 40px;
            font-weight: 700;
            text-align: center;
            @media (max-width: 576px) {
              padding: 10px 20px;
            }
            @media (max-width: 390px) {
              span {
                display: none;
              }
            }

            .fb-log {
              @media (max-width: 576px) {
                padding-bottom: 3px;
              }
            }
          }

          &.google {
            color: #ffffff;
            background: #D14D41;
            padding: 10px 30px;
            font-size: 14px;
            height: 40px;
            font-weight: 700;
            text-align: center;
            @media (max-width: 576px) {
              padding: 8px 18px;
            }
            @media (max-width: 390px) {
              span {
                display: none;
              }
            }

            .gg-login {
            }
          }
        }

        &.no-account {
          font-size: 14px;
          color: #6B6D70;

          .register {
            font-weight: 500;
            color: #004390;
            text-decoration-line: underline;
          }
        }

        &.password {
          .action {
            right: 16px;
          }
        }
      }

      .line {
        height: 1px;
        width: 350px;
        background: #004390;
      }

      .or {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        @media (max-width: 576px) {
          padding-top: 10px;
          padding-bottom: 0;
        }
      }
    }
  }
}
</style>
